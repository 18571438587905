import { useLoaderData, useSearchParams } from "@remix-run/react";
import { FlexRowCenter } from "~/components/flex-box";
import Signin from "~/page-sections/sessions/Signin";

import { action, loader } from "~/route-containers/authentication/login";
export { action, loader };

export default function LoginPage() {
  const { message } = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirect-to") || "/";
  return (
    <FlexRowCenter flexDirection="column" sx={{ my: 3 }}>
      <Signin message={message} redirectTo={redirectTo} />
    </FlexRowCenter>
  );
}
